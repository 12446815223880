.cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* see notes below */
    grid-auto-rows: minmax(200px, auto);
    grid-gap: 2rem;
    padding-bottom: 25px;
  }
.loadMore {
  background-color: #EC5328;
  width: 122px;
  height: 33px;
  border-radius: 16.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}
.loading {
  color: #EC5328;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 800;
}
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #EC5328;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}