.optionContainer {
    display: flex;
    align-items: center;
  }
  .radioOptionTitle {
    font-size: 17px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 16px;
  }