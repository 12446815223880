.inviteModal {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: '100%';
  z-index: 20;
}

.constrainedWidth {
  border-radius: 0.5rem;
  width: 600px;
}
