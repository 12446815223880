.card {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    color: #5d5e5e;
    outline: 0;
    // width: 290px;
  }
  .card:hover {
    cursor: pointer;
  }
  .cardImg {
    height: 192px;
    border-radius: 24px;
    object-fit: contain;
    // object-fit: cover;
  }
  .imgBackground {
    align-items: center;
    height: 192px;
    justify-content: center;
    display: flex;
    background: linear-gradient(107.98deg, #4C4D4E 0%, #231F20 100%);
    border-radius: 24px;
  }
  caption {
    color: #949598;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .title {
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    color: #231F20;
  }
  .subtitle {
    font-size: 16px;
    line-height: 20px;
    color: #4C4D4E;
    margin-top: 16px;
  }
