.callButton {
  background-color: #344966;
  color: #fff;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 999px;
  margin-bottom: 20px;
  font-size: 14px;
}
