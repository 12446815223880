.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.radioTitle {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radioUnderline {
  border-bottom-width: 1px;
}