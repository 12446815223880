.deactivateButton {
  color: #e92121;
}

.headerRowContainer {
  height: 40px;
  width: '100%';
  display: flex;
  flex-direction: row;
}

.headerLabelContainer {
  flex: 1;
}

.headerLabel {
  text-align: center;
}

.tableHeaderCellBorder {
  border-right: 1px solid #dedddb;
}

.standardCell {
  display: flex;
  flex: 1;
  overflow: hidden;

  span {
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}
