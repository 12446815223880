.title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 5px;
}
.saveButton {
  background-color: #EC5328;
  color: #FFF;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 999px;
  margin-top: 20px;
}