.callButton {
    background-color: #EC5328;
    color: #FFF;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 999px;
    margin-bottom: 20px;
    font-size: 14px;
}
.callButton:focus { outline: 0; }
.contactText {
    font-size: 18px;
    margin-top: 20;
    font-weight: 600;
    text-align: 'center';
    margin-top: 20px;
    margin-bottom: 20px;
}