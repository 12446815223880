.responsive-body-text {
  margin-bottom: 3px;
}

.responsive-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.notification-container {
  padding: 10px 10px;
}

.normalContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dark-background {
  background-color: #f3f2f0;
}

.date-text {
  opacity: 0.5;
}
