.leftContainer {
  display: flex;
  flex-direction: 'row';
}

.photoContainer {
  align-items: center;
  background-color: #ec5328;
  border-radius: 25px;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
  margin-right: 10px;
}

.placeholderContainer {
  align-items: center;
  background-color: #f3f2f0;
  border-radius: 25px;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
  margin-right: 10px;
}

.rateDriverButton {
  align-items: center;
  background-color: #f3f2f0;
  border-radius: 30px;
  display: flex;
  height: 60px;
  justify-content: space-between;
  margin-top: 25px;
  padding: 0 10px 0 5px;
  width: 275px;

  img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }

  span {
    font-size: 14px;
  }

  .driverName {
    font-size: 16px;
    font-weight: 800;
  }
}

.textContainer {
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.routeBtn {
  outline: 'none';
}
