.empty-state-container {
  width: '100%';
  display: flex;
  align-items: center;
  justify-content: center;
}

empty-state-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashBtn {
  background-color: #EC5328;
  width: 182px;
  height: 33px;
  border-radius: 16.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

a:hover {
  color: white;
}
